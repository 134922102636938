import Link from 'next/link';
import React from 'react';
import { qsStringify } from 'utils';
import { AnchorProps } from '~/components/Links/A';
import { RailsFlattenedFacetQuery } from '~/typings/services/rails/facets';
import extractPathname from '~/utils/extractPathname';

export type ShopLinkProps = AnchorProps & {
  // Use the internal router rather than a hard link
  internalRouter?: boolean;

  // Prefetch? In the old architecture there's a bug where it always 500s. Defaults to false.
  prefetch?: boolean;

  path?: string;
  query?: RailsFlattenedFacetQuery;
  type?: 'shop' | 'sm' | 'nav';
};

export function generateShopLink({
  query,
  path,
  type = 'shop',
}: Pick<ShopLinkProps, 'query' | 'path' | 'type'>) {
  if (!path) {
    return null;
  }

  if (path == '#') {
    return null;
  }

  const stringifiedQuery = qsStringify(query);
  const anchorProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = {};
  if (!path) {
    return `/search${stringifiedQuery}`;
  }

  if (path.startsWith('/search')) {
    return path;
  }

  const keys = query ? Object.keys(query) : [];

  if (query && keys.length > 0) {
    if (!query.page) {
      anchorProps.rel = 'nofollow noindex';
    }
  }

  let fullPath: string;

  if (path.startsWith('http')) {
    const extracted = extractPathname(path);
    if (!extracted.startsWith(`/${type}`)) {
      const split = extracted.split('/');
      split.splice(1, 1, type);
      fullPath = split.join('/') + stringifiedQuery;
    } else {
      fullPath = extracted + stringifiedQuery;
    }
  } else if (path.startsWith(`/${type}`)) {
    fullPath = path + stringifiedQuery;
  } else if (path.startsWith(type)) {
    fullPath = `/${path}${stringifiedQuery}`.replace('//', '/');
  } else {
    fullPath = `/${type}/${path}${stringifiedQuery}`.replace('//', '/');
  }

  return fullPath;
}

const ShopLink: React.FC<ShopLinkProps> = ({
  children,
  internalRouter,
  path,
  prefetch,
  query,
  rel,
  type = 'shop',
  ...props
}) => {
  const anchorProps: React.AnchorHTMLAttributes<HTMLAnchorElement> = {};
  const keys = query ? Object.keys(query) : [];

  const href = generateShopLink({ query, path, type });

  if (query && keys.length > 0 && !query.page) {
    anchorProps.rel = 'nofollow noindex';
  } else {
    anchorProps.rel = rel;
  }

  if (!href) {
    return (
      <span {...anchorProps} {...props}>
        {children}
      </span>
    );
  }

  if (internalRouter) {
    return (
      <Link
        href="/shop/[...lander]"
        {...anchorProps}
        {...props}
        as={href}
        prefetch={prefetch || false}
      >
        {children}
      </Link>
    );
  }

  return (
    <a {...anchorProps} {...props} href={href}>
      {children}
    </a>
  );
};

export default ShopLink;
